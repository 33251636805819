import {
    AUSTRIAN_PLATE_MATCH,
    DENMARK_PLATE_MATCH,
    GERMAN_PLATE_MATCH,
    ITALY_PLATE_MATCH,
    POLAND_PLATE_MATCH,
    SWISS_PLATE_MATCH
} from '../../utils/licensePlate'

export default {
    DE: GERMAN_PLATE_MATCH,
    AT: AUSTRIAN_PLATE_MATCH,
    CH: SWISS_PLATE_MATCH,
    IT: ITALY_PLATE_MATCH,
    PL: POLAND_PLATE_MATCH,
    DK: DENMARK_PLATE_MATCH,
    INT: /([a-z0-9]+){1}/gi
}
