import { ExtendedTheme } from '../../../../theme'
import InnerComponentStyleProps from '../../types'

export const getInputBorderColor =
    (valid: boolean) =>
    ({ $isFocused, $theme, $disabled, $readOnly }: InnerComponentStyleProps & { $theme: ExtendedTheme }) => {
        if (valid) return $theme.colors.positive200
        if ($disabled && !$readOnly) return 'transparent'
        if ($isFocused) return $theme.colors.primary
        return $theme.colors.primary200
    }

export const getInputBorderWidth =
    (_valid: boolean) =>
    ({ $disabled, $readOnly }: Partial<InnerComponentStyleProps>) => {
        if ($disabled && !$readOnly) return '0px'
        return '1px'
    }
