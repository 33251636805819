import { useStyletron } from 'baseui'
import { Button as BaseUIButton } from 'baseui/button'
import React from 'react'
import { ExtendedTheme } from '../../theme'
import { BUTTON_SHAPE_STYLES, BUTTON_TYPE_STYLE, BUTTON_USE_STYLES, COMMON_STYLES } from './styles'
import {
    BUTTON_KIND,
    BUTTON_KIND_CAPITALIZED,
    BUTTON_SHAPE,
    BUTTON_SIZE,
    BUTTON_TYPE,
    BUTTON_USE,
    BUTTON_USE_CAPITALIZED,
    IButton
} from './types'

const getButtonType = ($kind: BUTTON_KIND, $use: BUTTON_USE) => {
    const kind = BUTTON_KIND_CAPITALIZED[$kind as BUTTON_KIND]
    const use = BUTTON_USE_CAPITALIZED[$use]
    return BUTTON_TYPE[`${use}${kind}` as keyof typeof BUTTON_TYPE]
}

const getEnhancerColor = ($theme: ExtendedTheme, $use: BUTTON_USE, $kind: BUTTON_KIND, $dark: boolean) => {
    if ($use !== BUTTON_USE.default) return 'currentcolor'
    if ($kind === BUTTON_KIND.secondary) return 'currentcolor'
    const dark = $theme?.controls?.buttons[$kind]?.hasDark && $dark ? 'Dark' : ''
    return `button${getButtonType($kind, $use)}${dark}Text`
}

export const DEFAULT_BUTTON_SHAPE = {
    [BUTTON_USE.default]: BUTTON_SHAPE.pill,
    [BUTTON_USE.icon]: BUTTON_SHAPE.circle,
    [BUTTON_USE.label]: BUTTON_SHAPE.pill,
    undefined: BUTTON_SHAPE.default
}

/*
 A button component that can be used to display a button with different styles and sizes, and usage.
 Please check theme file in .storybook/theme.ts and adapt your project theme for the correct behavior/look of the component.
*/

const Button: React.FC<IButton> = ({
    children,
    overrides,
    $size = BUTTON_SIZE.small,
    disabled = false,
    $kind = BUTTON_KIND.primary,
    $dark = false,
    $use = BUTTON_USE.default,
    $shape = DEFAULT_BUTTON_SHAPE[$use],
    ...baseProps
}) => {
    //Label and Icon primary will have different coloring
    const buttonType = React.useMemo(() => {
        const kind = BUTTON_KIND_CAPITALIZED[$kind as BUTTON_KIND]
        const use = BUTTON_USE_CAPITALIZED[$use]
        return BUTTON_TYPE[`${use}${kind}` as keyof typeof BUTTON_TYPE]
    }, [$kind, $shape])

    const [, theme] = useStyletron()

    const enhancerColor = React.useMemo(
        () => getEnhancerColor(theme as ExtendedTheme, $use, $kind, $dark),
        [$use, $kind, $dark, theme]
    )

    const styles = React.useMemo(
        () => ({
            ...overrides,
            BaseButton: {
                ...overrides?.BaseButton,
                style: ({ $theme }: { $theme: ExtendedTheme }) => {
                    return {
                        ...COMMON_STYLES({ $theme }),
                        ...(BUTTON_USE_STYLES[$use] || BUTTON_USE_STYLES.default)({ $theme, $size }),
                        ...BUTTON_TYPE_STYLE({ $theme, $dark, buttonType, $kind, disabled }),
                        ...(BUTTON_SHAPE_STYLES[$shape] || BUTTON_SHAPE_STYLES.default)({ $size }),
                        ...overrides?.BaseButton?.style
                    }
                }
            },
            StartEnhancer: {
                style: ({ $theme }: { $theme: any }) => {
                    const dark = $theme.controls?.buttons[$kind].hasDark && $dark ? 'Dark' : ''
                    return {
                        justifyContent: 'end',
                        marginRight: '8px',
                        ...(disabled
                            ? {
                                  color: $theme.colors[`button${buttonType}${dark}DisabledText`],
                                  fill: $theme.colors[`button${buttonType}${dark}DisabledText`]
                              }
                            : {
                                  color: enhancerColor,
                                  fill: enhancerColor
                              }),
                        ...overrides?.StartEnhancer?.style
                    }
                }
            },
            EndEnhancer: {
                style: ({ $theme }: { $theme: any }) => {
                    const dark = $theme.controls?.buttons[$kind].hasDark && $dark ? 'Dark' : ''
                    return {
                        marginLeft: '8px',
                        ...(disabled
                            ? {
                                  color: $theme.colors[`button${buttonType}${dark}DisabledText`],
                                  fill: $theme.colors[`button${buttonType}${dark}DisabledText`]
                              }
                            : {
                                  color: enhancerColor,
                                  fill: enhancerColor
                              }),
                        ...overrides?.EndEnhancer?.style
                    }
                }
            }
        }),
        [$size, disabled, buttonType, $dark]
    )

    return (
        <BaseUIButton
            {...baseProps}
            disabled={disabled}
            overrides={{ ...styles }}
            shape={$shape}
            data-track-element_type="button"
        >
            {children}
        </BaseUIButton>
    )
}

export default Button
