import { createLightTheme, createThemedStyled, createThemedUseStyletron, createThemedWithStyle, Theme } from 'baseui'
import { borders } from './borders'
import { colors } from './colors'
import { ThemeProvider } from './context'
import { primitives } from './primitives'
import { sizing } from './sizing'
import { typography } from './typography'

export const overrides = (scale: number) => ({
    sizing: sizing(scale),
    typography: typography(),
    borders: borders(),
    controls: {
        buttons: {
            primary: {
                /** true */
                hasDark: true
            },
            secondary: {
                /** true */
                hasDark: true
            },
            tertiary: {
                /** false */
                hasDark: false
            },
            ghost: {
                /** false */
                hasDark: false
            }
        }
    },
    colors,
    /**
     * {\
     *  small: '@media screen and (max-width: 767px)',\
     *  medium: '@media screen and (max-width: 1024px)',\
     *  large: '@media screen and (max-width: 1441px)'\
     * }
     */
    mediaQuery: {
        small: '@media screen and (max-width: 767px)',
        medium: '@media screen and (min-width: 768px)',
        large: '@media screen and (min-width: 1025px)'
    }
})

type ExtendedTheme = Theme & ReturnType<typeof overrides> & { colors: typeof primitives }
type ColorKey = keyof ExtendedTheme['colors']
type BorderKey = keyof ExtendedTheme['borders']

const theme = createLightTheme(primitives, overrides(1)) as ExtendedTheme
const styled = createThemedStyled<ExtendedTheme>()
const withStyle = createThemedWithStyle<ExtendedTheme>()
const useStyletron = createThemedUseStyletron<ExtendedTheme>()

export { styled, ThemeProvider, useStyletron, withStyle }
export type { ExtendedTheme, ColorKey, BorderKey }
export default theme
