import { generateContext } from 'parkdepot-shared/utils/contextGenerator'
import { IFormPlateInputOverrides } from './FormPlateInput'
import { VALIDITY } from './types'

export interface ILicensePlateInputContext {
    country?: string
    value: string
    validity: VALIDITY
    onChange: (value: string) => void
    disabled?: boolean
    error: boolean
    readOnly: boolean
    withKeyboard: boolean
    overrides?: IFormPlateInputOverrides
    firstPartEmptyError: boolean
    setFirstPartEmptyError: (a: boolean) => void
}

const [LicensePlateInputContext, LicensePlateInputProvider] = generateContext<ILicensePlateInputContext>()

export { LicensePlateInputContext }
export default LicensePlateInputProvider
