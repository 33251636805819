import { ConfigurationOverrideFunction, ConfigurationOverrideObject } from 'baseui/helpers/overrides'
import { BorderKey, ColorKey, ExtendedTheme } from '../../theme'
import { BUTTON_KIND, BUTTON_SHAPE, BUTTON_SIZE, BUTTON_TYPE, BUTTON_USE } from './types'

export const COMMON_STYLES: ConfigurationOverrideFunction = ({ $theme }) => ({
    display: 'inline-flex',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    transition: `color ${$theme.animation.easeInOutCurve} .2s, background ${$theme.animation.easeInOutCurve} .2s, border ${$theme.animation.easeInOutCurve} .2s`,
    ...$theme.typography.font350
})

export const BUTTON_DIMENSIONS = {
    [BUTTON_USE.icon]: {
        [BUTTON_SIZE.compact]: '24px',
        [BUTTON_SIZE.small]: '24px',
        [BUTTON_SIZE.medium]: '32px',
        [BUTTON_SIZE.large]: '40px',
        default: '24px'
    },
    default: {
        [BUTTON_SIZE.compact]: '32px',
        [BUTTON_SIZE.small]: '40px',
        [BUTTON_SIZE.medium]: '50px',
        [BUTTON_SIZE.large]: '60px',
        default: '32px'
    }
}

export interface ButtonStyleFunctionArgs {
    $theme: ExtendedTheme
    $size: BUTTON_SIZE
}

export interface ButonTypeStyle {
    $theme: ExtendedTheme
    $kind: BUTTON_KIND
    buttonType: BUTTON_TYPE
    $dark: boolean
    disabled: boolean
}
export type StyleFunction = (props: ButtonStyleFunctionArgs) => ConfigurationOverrideObject

export const BUTTON_USE_STYLES: Record<string, StyleFunction> = {
    [BUTTON_USE.icon]: ({ $theme, $size }) => ({
        height: BUTTON_DIMENSIONS[BUTTON_USE.icon][$size || 'default'],
        width: BUTTON_DIMENSIONS[BUTTON_USE.icon][$size || 'default']
    }),
    default: ({ $theme, $size }) => ({
        paddingLeft: `calc(${$theme.sizing.scale400} + ${$theme.sizing.scale600})`,
        paddingRight: `calc(${$theme.sizing.scale400} + ${$theme.sizing.scale600})`,
        height: BUTTON_DIMENSIONS.default[$size || 'default']
    })
}

export const BUTTON_SHAPE_STYLES: Record<string, (props: { $size: BUTTON_SIZE }) => ConfigurationOverrideObject> = {
    [BUTTON_SHAPE.pill]: ({ $size }) => ({
        padding: $size === BUTTON_SIZE.large ? '7px, 12px, 7px, 8px' : '9px, 20px, 9px, 20px'
    }),
    default: () => ({})
}

export const BUTTON_TYPE_STYLE = ({ $theme, buttonType, $dark, $kind, disabled }: ButonTypeStyle) => {
    const dark = $theme?.controls?.buttons[$kind]?.hasDark && $dark ? 'Dark' : ''
    return {
        color: $theme.colors[`button${buttonType}${dark}Text` as ColorKey],
        backgroundColor: $theme.colors[`button${buttonType}${dark}Fill` as ColorKey],
        ...($theme.borders[`button${buttonType}${dark}Border` as BorderKey]
            ? {
                  border: $theme.borders[`button${buttonType}${dark}Border` as BorderKey]
              }
            : {
                  border: `2px solid ${
                      $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'Fill'}` as ColorKey]
                  }`
              }),
        [':hover']: {
            backgroundColor:
                $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'Hover'}` as ColorKey],
            color: $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledText' : 'HoverText'}` as ColorKey],
            border:
                $theme.borders[
                    `button${buttonType}${dark}${disabled ? 'BorderDisabled' : 'HoverBorder'}` as BorderKey
                ] ||
                `2px solid ${
                    $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'Hover'}` as ColorKey]
                }`
        },
        [':active']: {
            backgroundColor:
                $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'Active'}` as ColorKey],
            color: $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledText' : 'SelectedText'}` as ColorKey],
            border:
                $theme.borders[
                    `button${buttonType}${dark}${disabled ? 'BorderDisabled' : 'HoverBorder'}` as BorderKey
                ] ||
                `2px solid ${
                    $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'Hover'}` as ColorKey]
                }`
        },
        [':focus']: {
            backgroundColor:
                $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'Active'}` as ColorKey],
            color: $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledText' : 'SelectedText'}` as ColorKey],
            border:
                $theme.borders[
                    `button${buttonType}${dark}${disabled ? 'BorderDisabled' : 'HoverBorder'}` as BorderKey
                ] ||
                `2px solid ${
                    $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'Hover'}` as ColorKey]
                }`
        },
        [':selected']: {
            backgroundColor:
                $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'SelectedFill'}` as ColorKey],
            color: $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledText' : 'SelectedText'}` as ColorKey],
            border:
                $theme.borders[
                    `button${buttonType}${dark}${disabled ? 'BorderDisabled' : 'HoverBorder'}` as BorderKey
                ] ||
                `2px solid ${
                    $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'Hover'}` as ColorKey]
                }`
        },
        [':disabled']: {
            color: $theme.colors[`button${buttonType}${dark}DisabledText` as ColorKey],
            backgroundColor: $theme.colors[`button${buttonType}${dark}DisabledFill` as ColorKey],
            ...($theme.borders[`button${buttonType}${dark}BorderDisabled` as BorderKey]
                ? {
                      border: $theme.borders[`button${buttonType}${dark}BorderDisabled` as BorderKey]
                  }
                : { border: `2px solid ${$theme.colors[`button${buttonType}${dark}DisabledFill` as ColorKey]}` })
        }
    }
}
