import { Input, InputProps, InternalState } from 'baseui/input'
import React from 'react'
import { ExtendedTheme } from '../../../theme'
import { LicensePlateInputContext } from '../LicensePlateInputContext'
import InnerComponentStyleProps, { VALIDITY } from '../types'
import { getInputBorderColor, getInputBorderWidth } from './utils/getBorderStyle'

const RightLpInput = ({ ...props }: InputProps & InternalState) => {
    const { validity } = React.useContext(LicensePlateInputContext)

    const valid = validity === VALIDITY.VALID

    const getBorderAndWidthColor = React.useMemo(
        () => ({ color: getInputBorderColor(valid), width: getInputBorderWidth(valid) }),
        [valid]
    )

    return (
        <Input
            {...props}
            overrides={{
                Root: {
                    style: ({
                        $isFocused,
                        $theme,
                        $disabled,
                        $readOnly
                    }: InnerComponentStyleProps & { $theme: ExtendedTheme }) => {
                        const borderColor = getBorderAndWidthColor.color({ $isFocused, $theme, $disabled, $readOnly })
                        const borderWidth = getBorderAndWidthColor.width({ $disabled, $readOnly })
                        return {
                            height: '100%',
                            minHeight: '40px',
                            flex: '3 1 0%',
                            backgroundColor: 'transparent',
                            borderLeftWidth: borderWidth,
                            borderRightWidth: borderWidth,
                            borderTopWidth: borderWidth,
                            borderBottomWidth: borderWidth,
                            borderBottomLeftRadius: '8px',
                            borderBottomRightRadius: '8px',
                            borderTopLeftRadius: '8px',
                            borderTopRightRadius: '8px',
                            ...(valid && {
                                borderLeftColor: borderColor,
                                borderRightColor: borderColor,
                                borderTopColor: borderColor,
                                borderBottomColor: borderColor
                            }),
                            ':hover': {
                                borderLeftColor: borderColor,
                                borderRightColor: borderColor,
                                borderTopColor: borderColor,
                                borderBottomColor: borderColor
                            },
                            ...props.overrides?.Root?.style
                        }
                    }
                },
                InputContainer: {
                    style: {
                        backgroundColor: 'transparent',
                        borderLeftColor: 'transparent',
                        borderRightColor: 'transparent',
                        borderTopColor: 'transparent',
                        borderBottomColor: 'transparent'
                    },
                    ...props.overrides?.InputContainer?.style
                },
                Input: {
                    props: props.overrides?.Input?.props ?? {},
                    style: ({ $theme, $disabled }) => ({
                        textAlign: 'center',
                        fontWeight: 600,
                        fontSize: '16px',
                        padding: '0',
                        color: `${$theme.colors.contentPrimary} !important`,
                        opacity: '1',
                        '-webkit-opacity': '1',
                        '-webkit-text-fill-color': 'none !important',
                        '::placeholder': {
                            color: $disabled ? '#1F1F1F' : '#E2E2E2'
                        },
                        ...props.overrides?.Input?.style
                    })
                }
            }}
        />
    )
}

export default RightLpInput
