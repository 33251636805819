import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface AppState {
    licensePlate: string
    siteId: string
    setLicensePlate(licensePlate: string): void
    cleanState: () => void
}

const useAppStore = create<AppState>()(
    devtools((set) => ({
        licensePlate: '',
        siteId: '',
        setLicensePlate: (licensePlate) => set(() => ({ licensePlate })),
        cleanState: () => set(() => ({ licensePlate: '', siteId: '' }))
    }))
)

export default useAppStore
