import { Theme } from 'baseui'

type InnerComponentStyleProps = {
    $isFocused: boolean
    $theme: Theme
    $disabled: boolean
    $readOnly: boolean
}

export enum VALIDITY {
    NEUTRAL = 'neutral',
    VALID = 'valid'
}

export default InnerComponentStyleProps
